import { useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { GET_SERVICES } from "../../components/graphql"
import Layout from "../../components/layout"
import Login from "../../components/login"
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import '../../assets/css/services.css';
import faqarrow from '../../assets/images/faq-arrow.png';
import $ from "jquery"
function ServicesIndexPage() {
  const getServices = useQuery(GET_SERVICES)
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userId, setUserID] = useState(undefined)
  const [phoneError, setphoneError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        $(document).ready(function () {
          $('.div-all').hide();
          $(".div-1").show();
          $( ".faqQuestionImage" ).each(function( index ) {
            $( this ).removeClass('active');
          });
          $(".arrow-1").addClass('active');
        });
        $(".link").on('click', function () {
            console.log("calklj",$(this).attr('data-ids'))
            var value = $(this).attr('data-ids')
            $('.div-all').hide();
            $(".div-" + value).show();
            $( ".faqQuestionImage" ).each(function( index ) {
              $( this ).removeClass('active');
            });
            $(".arrow-" + value).addClass('active');
        });
    }
  }
});
  return (
    <Layout setTitle="Services" tag="services">
      <Login
        open={open}
        setOpen={setOpen}
        login={login}
        setLogin={setLogin}
        setUsername={setUsername}
        setUserID={setUserID}
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        phoneError={phoneError}
        setphoneError={setphoneError}
      />
      <div className="main-content">
        <div className="container">
        <div className="services-section">
          <div className="serviceContent">
            {getServices?.data?.servicesMainPage && (
              <div className="service-container">
                <div className="title blue">
                  {getServices?.data?.servicesMainPage?.title} 
                </div>
                <div className="serviceDescription">
                  <ReactMarkdown
                    source={getServices?.data?.servicesMainPage?.description}
                    transformImageUri={uri =>
                      uri.startsWith("http")
                        ? uri
                        : `${process.env.BACKEND_URL}${uri}`
                    }
                  />
                </div>
              </div>
            )}
            <div className="image">
              <img
                src={
                  getServices?.data?.servicesMainPage?.image?.url
                    ? process.env.BACKEND_URL +
                      getServices?.data?.servicesMainPage?.image?.url
                    : ""
                }
                alt="imageSection"
              />
            </div>
          </div>
          <div className="servicesGrid">
            {getServices?.data?.servicesMainPage?.servicesCategoryContent
              ?.length > 0 && (
                getServices?.data?.servicesMainPage?.servicesCategoryContent?.map(
                    (category, index) => (
                      <>
                        <div className="servicesGrid-item">
                            <div key={category?.id} className='serviceGrid'>
                                <Link to={(`/services/${category?.url}`) ?? "/"}>
                                    <div className='serviceGridTitle subtitle'>{category?.mainPageTitle}</div>
                                </Link>
                                  <div className='mainPageDescription'>
                                    <ReactMarkdown
                                        source={category?.mainPageDescription}
                                        transformImageUri={uri =>
                                          uri.startsWith("http")
                                            ? uri
                                            : `${process.env.BACKEND_URL}${uri}`
                                        }
                                    />
                                </div>
                            </div>
                           
                        </div>
                        {(getServices?.data?.servicesMainPage?.servicesCategoryContent?.length ==
                                index + 1) && (
                                   <div className='servicesGrid-item serviceExplore'>
                                        <div className='firstSectionExtraContentTitle blue'>
                                            {getServices?.data?.servicesMainPage?.firstSectionExtraContentTitle}
                                        </div>
                                        <div className='firstSectionExtraContentDescription'>
                                            <ReactMarkdown
                                              source={ getServices?.data?.servicesMainPage?.firstSectionExtraContentDescription}
                                              transformImageUri={uri =>
                                                uri.startsWith("http")
                                                  ? uri
                                                  : `${process.env.BACKEND_URL}${uri}`
                                              }
                                            />
                                        </div>
                                        {getServices?.data?.servicesMainPage?.firstSectionExtraContentButtonName?
                                          <div className="cta-secondary">
                                            <Link to='/pricing' className='firstSectionExtraContentButtonName'>
                                                {
                                                    getServices?.data?.servicesMainPage?.firstSectionExtraContentButtonName
                                                }
                                            </Link>
                                          </div>
                                        :null}
                                    </div>
                                )}
                        </>
                    )
                )
            )}
          </div>

          {getServices?.data?.servicesMainPage?.faqSectionTitle && (
              <div className='faqSection'>
              <div className='faqSectionHeader'>
          
                  <img src={getServices?.data?.servicesMainPage?.faqSectionImage?.url
                      ? process.env.BACKEND_URL + getServices?.data?.servicesMainPage?.faqSectionImage?.url
                      : ''} alt="faqimage" className='faqSectionImage' />
          
                  <div className='faqSectionTitle'>
                      {getServices?.data?.servicesMainPage?.faqSectionTitle}
                  </div>
              </div>
              {getServices?.data?.servicesMainPage?.faqSectionContent?.length > 0 && 
                <div className='faqListContainer'>
                  {getServices?.data?.servicesMainPage?.faqSectionContent?.map((faq, index) => 
                    <div className='faqContent link' key={index} data-ids={(index + 1)?.toString()}>
                      <div className="faq-ques">
                        <div className={`faqQuestionImage arrow-${(index + 1)?.toString()}`}>
                            <img src={faqarrow} alt="faqarrow" />
                        </div>
                        <a className="link">{faq?.questionTitle}</a>
                      </div>
                      <div className={`div-all div-${(index + 1)?.toString()}`}>
                          <ReactMarkdown
                              source={faq?.questionAnswerDescription}
                              transformImageUri={uri =>
                                uri.startsWith("http")
                                  ? uri
                                  : `${process.env.BACKEND_URL}${uri}`
                              }
                          />
                      </div>
                  </div>
                  )}
              </div>}
          </div>
          )}
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServicesIndexPage
